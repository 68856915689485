import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-body d-flex align-items-center pt-3 pb-0" }
const _hoisted_2 = { class: "d-flex flex-column flex-grow-1 py-2 py-lg-8 me-2 align-items-center" }
const _hoisted_3 = {
  key: 0,
  href: "#",
  class: "mb-2 houston-fs-title text-white"
}
const _hoisted_4 = {
  key: 1,
  href: "#",
  class: "mb-2 houston-fs-title text-white"
}
const _hoisted_5 = { class: "houston-fs-desc text-white" }
const _hoisted_6 = { class: "d-flex align-items-stretch flex-shrink-0 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.maxUsers)
          ? (_openBlock(), _createElementBlock("a", _hoisted_3, _toDisplayString(_ctx.counter) + " / " + _toDisplayString(_ctx.maxUsers), 1))
          : (_openBlock(), _createElementBlock("a", _hoisted_4, _toDisplayString(_ctx.counter), 1)),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.description), 1),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.hasActionButton)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: `${_ctx.actionButton.url}`,
                class: _normalizeClass(["btn btn-sm btn-primary d-flex align-items-center fs-5", `${_ctx.actionButton.class}`])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: _normalizeClass(`las fs-1 la-${_ctx.actionButton.icon}`)
                  }, null, 2),
                  _createTextVNode(" " + _toDisplayString(_ctx.actionButton.name), 1)
                ]),
                _: 1
              }, 8, ["to", "class"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 2))
}