
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'statistics-widget-1',
  props: {
    widgetClasses: String,
    counter: Number,
    description: String,
    hasActionButton: Boolean,
    actionButton: Object,
    maxUsers: Number,
    image: String
  }
})
