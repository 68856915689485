
import DataTable from '@/components/mc-admin-datatable/DataTable.vue'
import StatisticsWidget from '@/components/mc-admin-widgets/StatisticsWidget1.vue'
import FilterDropdown from '@/views/mc-admin-session/FilterDropdown.vue'
import { defineComponent, ref, onMounted, computed, inject, watch } from 'vue'
import { useStore } from 'vuex'
import { setCurrentPageTitle, setCurrentPageTitleButton } from '@/core/helpers/breadcrumb'
import { getMedia } from '@/core/mc-admin-helpers/assets'
import {
  getStatusBadge,
  formatListDate,
  formatListHourInterval
} from '@/core/mc-admin-helpers/activity-session'
import { Actions, MutationsActions } from '@/store/enums/StoreEnums'
import { ActivitySessionFilter } from '@/store/modules/ActivitySessionModule'
import { NewLearningPath } from '@/store/modules/LearningPathModule'
import router from '@/router/clean'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { MetricsPayload } from '@/store/modules/StatisticModule'
import { ElNotification } from 'element-plus/es'
import TheObserveSessionModal from '@/views/mc-admin-session/TheObserveSessionModal.vue'

export default defineComponent({
  name: 'session-manager',
  components: {
    TheObserveSessionModal,
    StatisticsWidget,
    DataTable,
    FilterDropdown
  },
  setup () {
    const tableHeader = ref([
      {
        name: 'NAME',
        key: 'name',
        sortable: true
      },
      {
        name: 'STATUS',
        key: 'status',
        sortable: true
      },
      {
        name: 'SCHEDULED FOR',
        key: 'startDate',
        sortable: true
      },
      {
        name: 'PARTICIPANTS',
        key: 'participants',
        sortable: true
      },
      {
        name: 'Actions',
        key: 'actions'
      }
    ])
    const tableSearch = ref<string>('')
    const itemsPerPage = ref<number>(5)
    const selectedPage = ref<number>(1)
    const selectedActivitySession = ref()
    const store = useStore()

    const tableData = ref(computed(() => store.getters.getActivitySessions))
    const tableLoading = computed(() => store.getters.getActivitySessionRequestPending)

    const sessionCount = computed(() => store.getters.getActivitySessionsCount)
    const tablePagesCount = computed(() => Math.ceil(sessionCount.value / itemsPerPage.value))

    const sessionsInProgressCount = computed(() => store.getters.getActivitySessionsInProgressCount)
    const sessionsScheduledCount = computed(() => store.getters.getActivitySessionsScheduledCount)
    const sessionsFinishedCount = computed(() => store.getters.getActivitySessionsFinishedCount)

    let timeoutId = 0 as any

    const onItemsSearch = () => {
      clearTimeout(timeoutId)
      const payload: ActivitySessionFilter = { filter: { name: tableSearch.value, limit: itemsPerPage.value, offset: itemsPerPage.value * (selectedPage.value - 1) } }
      const countPayload: ActivitySessionFilter = { filter: { name: tableSearch.value } }
      timeoutId = setTimeout(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS, payload)
        store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_COUNT, countPayload)
      }, 500)
    }

    const onCurrentPageChange = (page) => {
      selectedPage.value = page
      const payload: ActivitySessionFilter = { filter: { limit: itemsPerPage.value, offset: itemsPerPage.value * (page - 1) } }
      if (tableSearch.value !== '') {
        payload.filter.name = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS, payload)
    }

    const onItemsPerPageChange = (rowsPerPage, page) => {
      itemsPerPage.value = rowsPerPage
      const payload: ActivitySessionFilter = { filter: { limit: rowsPerPage, offset: rowsPerPage * (--page - 1) } }
      if (tableSearch.value !== '') {
        payload.filter.name = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS, payload)
    }

    const onDeleteAction = (activitySessionUuid) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        html: '<div style="display: flex; flex-direction: column; "><i class="las la-exclamation-circle fs-1 mb-2"></i>All participants will receive cancellation emails.</div>',
        animation: false,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(Actions.API_DELETE_ACTIVITY_SESSION, activitySessionUuid).then((response) => {
            const payload: ActivitySessionFilter = { filter: { limit: itemsPerPage.value } }
            store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS, payload)
            store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_COUNT)
            store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_IN_PROGRESS_COUNT)
            store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_SCHEDULED_COUNT)
            store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_FINISHED_COUNT)
            ElNotification.success({
              message: 'Activity session deleted.',
              dangerouslyUseHTMLString: true,
              customClass: 'houston-notification'
            })
          }).catch((error) => {
            console.log(error)
          })
        }
      })
    }
    const onObserveSessionClicked = (activitySession) => {
      selectedActivitySession.value = activitySession
    }

    const onDownloadAnalytics = (activitySessionUuid) => {
      const filter: MetricsPayload = {
        metrics: ['session_statistics'],
        filter: {
          activitySessionUuid: activitySessionUuid,
          format: 'xlsx'
        }
      }
      store.dispatch(Actions.API_GET_METRICS_BY_FILTER, filter)
    }

    const emitter = inject('emitter') as any // Inject `emitter`
    emitter.on('create-learning-path', (value) => {
      const newLearningPath = {
        title: 'Untitled new learning path',
        description: 'No description',
        objective: 'No objective',
        settings: {
          allowNotifyManagers: 1,
          allowMultipleAnswers: 0
        }
      } as NewLearningPath

      store.dispatch(Actions.API_CREATE_LEARNING_PATH, newLearningPath).then((response) => {
        router.push('/deploy/' + response.payload.uuid)
      }).catch((response) => {
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
    })

    onMounted(() => {
      const payload: ActivitySessionFilter = { filter: { limit: itemsPerPage.value } }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS, payload)
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_COUNT)
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_IN_PROGRESS_COUNT)
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_SCHEDULED_COUNT)
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_FINISHED_COUNT)

      setCurrentPageTitle('Session manager')
      setCurrentPageTitleButton({ name: 'New Learning Path', url: '', class: 'houston-btn-dark houston-btn-bounce', emits: { actionName: 'create-learning-path' } })
      watch(() => tableData.value, (updated, oldAccount) => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
      })

      if (tableData.value) {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
      }
    })

    return {
      tableHeader,
      tableData,
      tableSearch,
      tableLoading,
      tablePagesCount,
      sessionsInProgressCount,
      sessionsScheduledCount,
      sessionsFinishedCount,
      selectedActivitySession,
      onItemsSearch,
      onItemsPerPageChange,
      onCurrentPageChange,
      onDeleteAction,
      onDownloadAnalytics,
      getMedia,
      getStatusBadge,
      formatListDate,
      formatListHourInterval,
      onObserveSessionClicked
    }
  }
})
