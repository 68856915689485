
import { defineComponent, ref } from 'vue'

interface Filter {
  status: string;
  scheduledFor: string;
}

export default defineComponent({
  name: 'filter-dropdown',
  components: {},
  setup () {
    const data = ref<Filter>({
      status: '1',
      scheduledFor: '1'
    })

    return {
      data
    }
  }
})
